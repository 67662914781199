.app-content {
  display: flex;
  height: calc(100vh - 68px);
}
.app-content.login{
  height: calc(100vh - 90px);
}
.main-content {
  flex: 1;
  /* height: 100%; */
  background-color: var(--hex-twelve);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.main-content::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 900px) {
  .sidebar {
    display: none;
  }
}
