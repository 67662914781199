@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

html {
  --hex-one: #c00000;
  --hex-two: #ff000b;
  --hex-three: #f55e61;
  --hex-four: #121212;
  --hex-five: #f0f1f7;
  --hex-six: #6b344e;
  --hex-seven: #f5f5f5;
  --hex-eight: #818181;
  --hex-nine: #9597a6;
  --hex-ten: #fcc8c9;
  --hex-eleven: rgb(0, 98, 255);
  --hex-twelve: #f4f7fc;
  --hex-thirteen: #171725;
  --hex-fourteen: #f3f3f3;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
